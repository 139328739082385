import { Dispatch } from 'redux';
import { IntervaloTempo } from 'shared/types/apiTypes';
import { ItemFilial } from 'features/listaCargas/api/listaCargasApiTypes';
import { atualizarFiliaisOperation } from 'features/listaCargas/redux/listaCargasOperations';
import {
  ParametrosTempo,
  DadosPermissaoColaborador,
  salvarPermissaoColaboradorRequest,
  CategoriaVeiculo,
  FreteTabeladoRegiao,
  FreteTabeladoMacroId,
  ColaboradoresNegociadores,
  DadosTabelaPrecificacoes,
  ConfiguracoesColaboradorNegociador,
  listaColaboradorNegociador,
  MacroFilialRequest,
  BuscarFiliaisResponse,
  BuscarCidadesResponse,
  AtualizarCidadesRequest,
  EstadosResponse,
  CadastrarCidadesRequest,
} from '../api/configuracoesApiTypes';
import {
  abrirSnackbarAviso,
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from '../../../shared/components/snackbar/redux/snackbarAction';
import Role from '../../../shared/constants/RoleColaborador';

import * as manager from '../api/configuracoesManager';

const tratarErro = (error: Error) => (dispatch: Dispatch) => {
  dispatch(abrirSnackbarErro({ mensagem: error.message }));
};

export const buscarPermissaoColaboradores = async (
  setColaboradores: (dadosPermissao: DadosPermissaoColaborador[]) => void,
  dispatch: Dispatch,
): Promise<void> =>
  manager
    .obterPermissaoColaboradores()
    .then((resposta) => {
      setColaboradores(resposta.dadosPermissao);
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });

export const buscarColaboradoresNegociadores = async (
  setColaboradoresNegociadores: (dados: ColaboradoresNegociadores) => void,
  dispatch: Dispatch,
): Promise<void> => {
  try {
    const resposta = await manager.obterColaboradoresNegociadores();
    setColaboradoresNegociadores(resposta);
  } catch (error: unknown) {
    if (error instanceof Error) {
      tratarErro(error)(dispatch);
    }
  }
};

export const salvarPermissaoColaborador = async (
  request: salvarPermissaoColaboradorRequest,
  dispatch: Dispatch,
): Promise<void> =>
  manager
    .salvarPermissaoColaborador(request)
    .then(() => {
      dispatch(abrirSnackbarSucesso({ mensagem: 'Perfil salvo com sucesso' }));
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });

export const toggleRoleSupervisor = (old: number[]): number[] => {
  return old.includes(Role.SUPERVISOR)
    ? old.filter((o) => o !== Role.SUPERVISOR)
    : [...old, Role.SUPERVISOR];
};

export const toggleRoleNegociador = (old: number[]): number[] => {
  return old.includes(Role.NEGOCIADOR)
    ? old.filter((o) => o !== Role.NEGOCIADOR)
    : [...old, Role.NEGOCIADOR];
};

export const buscarParametrosTempo = async (
  setParametrosTempo: (parametros: ParametrosTempo) => void,
  dispatch: Dispatch,
): Promise<void> =>
  manager
    .obterParametrosTempo()
    .then((resposta) => {
      setParametrosTempo(resposta);
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });

export const salvarParametrosTempo = async (
  sugestaoRoboHabilitada: boolean,
  isFreteTabeladoHabilitado: boolean,
  tempoInativo: IntervaloTempo,
  tempoAprovacao: IntervaloTempo,
  horasVencimento: IntervaloTempo,
  dispatch: Dispatch,
): Promise<void> =>
  manager
    .salvarParametrosTempo(
      sugestaoRoboHabilitada,
      isFreteTabeladoHabilitado,
      tempoInativo,
      tempoAprovacao,
      horasVencimento,
    )
    .then(() => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Parâmetros do Sistema salvos com sucesso',
        }),
      );
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });

export const salvarParametrosFilial = async (
  value: ItemFilial,
  dispatch: Dispatch,
): Promise<void> =>
  manager
    .salvarParametrosFilial(value)
    .then(() => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Parâmetros salvos com sucesso',
        }),
      );
      atualizarFiliaisOperation()(dispatch);
    })
    .catch((error) => {
      tratarErro(error)(dispatch);
    });

export const buscarCategoriaVeiculosFreteTabelado = async (
  setDados: (dados: CategoriaVeiculo[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .buscarCategoriaVeiculosFreteTabelado()
    .then(async (resposta) => {
      const dados = Array.isArray(resposta) ? resposta : [resposta];
      setDados(dados);
    })
    .catch((e) => {
      tratarErro(e)(dispatch);
    });
};

export const atualizarCategoriaVeiculoFreteTabelado = async (
  categoriaVeiculo: CategoriaVeiculo,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .atualizarCategoriaVeiculoFreteTabelado(categoriaVeiculo)
    .then(() => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Categoria do veiculo foi salva!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarAviso({
          mensagem: 'Preencha os campos!',
        }),
      );
    });
};

export const buscarFreteTabeladoMacros = async (
  setRegioes: (dados: FreteTabeladoRegiao[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .buscarFreteTabeladoMacros()
    .then(async (resposta) => {
      const dados = Array.isArray(resposta) ? resposta : [resposta];
      setRegioes(dados);
    })
    .catch((e) => {
      tratarErro(e)(dispatch);
    });
};

export const buscarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  setDadosRegiaoId: (dados: FreteTabeladoMacroId[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .buscarFreteTabeladoPorIdMacro(idMacro)
    .then(async (resposta) => {
      const dados = Array.isArray(resposta) ? resposta : [resposta];
      setDadosRegiaoId(dados);
    })
    .catch(() => {
      setDadosRegiaoId([]);
      dispatch(
        abrirSnackbarAviso({
          mensagem: 'Região não possui veiculos cadastrados.',
        }),
      );
    });
};

export const salvarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
  dispatch: Dispatch,
  setDadosRegiaoId: (dados: FreteTabeladoMacroId[]) => void,
): Promise<void> => {
  manager
    .salvarFreteTabeladoPorIdMacro(idMacro, idCategoriaVeiculo, request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Salvo!',
        }),
      );

      await buscarFreteTabeladoPorIdMacro(idMacro, setDadosRegiaoId, dispatch);
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Existe campos vazios',
        }),
      );
    });
};

export const atualizarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
  dispatch: Dispatch,
  setDadosRegiaoId: (dados: FreteTabeladoMacroId[]) => void,
  messageDispatch = true,
): Promise<void> => {
  manager
    .atualizarFreteTabeladoPorIdMacro(idMacro, idCategoriaVeiculo, request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: messageDispatch ? 'Dados atualizados!' : 'Linha removida!',
        }),
      );

      await buscarFreteTabeladoPorIdMacro(idMacro, setDadosRegiaoId, dispatch);
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Existem campos vazios!',
        }),
      );
    });
};

export const deleteFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  dispatch: Dispatch,
  descricaoCategoria: string,
  setDadosDeleteRegiaoId: (dados: FreteTabeladoMacroId[]) => void,
): Promise<void> => {
  manager
    .deleteFreteTabeladoPorIdMacro(idMacro, idCategoriaVeiculo)
    .then(async () => {
      dispatch(
        abrirSnackbarAviso({
          mensagem: `Todos os dados da categoria ${descricaoCategoria} foram apagados`,
        }),
      );
      await buscarFreteTabeladoPorIdMacro(
        idMacro,
        setDadosDeleteRegiaoId,
        dispatch,
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro! Linha não existe.',
        }),
      );
      setDadosDeleteRegiaoId([]);
    });
};

export const buscarFreteTabeladoPorIdFilial = async (
  idFilial: string,
  setDadosVeiculosGrupos: (dados: FreteTabeladoMacroId[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .buscarFreteTabeladoPorIdFilial(idFilial)
    .then(async (resposta) => {
      const dados = Array.isArray(resposta) ? resposta : [resposta];
      setDadosVeiculosGrupos(dados);
    })
    .catch(() => {
      setDadosVeiculosGrupos([]);
      dispatch(
        abrirSnackbarAviso({
          mensagem: 'Regiões não possuem veiculos cadastrados.',
        }),
      );
    });
};

export const buscarFreteTabeladoMacroFilial = async (
  idFilial: string,
  setDadosGruposRegioes: (dados: FreteTabeladoRegiao[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .buscarFreteTabeladoMacroFilial(idFilial)
    .then(async (resposta) => {
      const dados = Array.isArray(resposta) ? resposta : [resposta];
      setDadosGruposRegioes(dados);
    })
    .catch(() => {
      setDadosGruposRegioes([]);
      dispatch(
        abrirSnackbarAviso({
          mensagem: 'Grupo de regiões não existe.',
        }),
      );
    });
};

export const salvarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
  dispatch: Dispatch,
  setDadosVeiculosGrupos: (dados: FreteTabeladoMacroId[]) => void,
): Promise<void> => {
  manager
    .salvarFreteTabeladoPorMacroFilial(idFilial, idCategoriaVeiculo, request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Salvo!',
        }),
      );

      await buscarFreteTabeladoPorIdFilial(
        idFilial,
        setDadosVeiculosGrupos,
        dispatch,
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Existe campos vazios',
        }),
      );
    });
};

export const atualizarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
  dispatch: Dispatch,
  setDadosVeiculosGrupos: (dados: FreteTabeladoMacroId[]) => void,
  messageDispatch = true,
): Promise<void> => {
  manager
    .atualizarFreteTabeladoPorMacroFilial(idFilial, idCategoriaVeiculo, request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: messageDispatch ? 'Dados atualizados!' : 'Linha removida!',
        }),
      );

      await buscarFreteTabeladoPorIdFilial(
        idFilial,
        setDadosVeiculosGrupos,
        dispatch,
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Existem campos vazios!',
        }),
      );
    });
};

export const deleteFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  dispatch: Dispatch,
  descricaoCategoria: string,
  setDadosVeiculosGrupos: (dados: FreteTabeladoMacroId[]) => void,
): Promise<void> => {
  manager
    .deleteFreteTabeladoPorMacroFilial(idFilial, idCategoriaVeiculo)
    .then(async () => {
      dispatch(
        abrirSnackbarAviso({
          mensagem: `Todos os dados da categoria ${descricaoCategoria} foram apagados`,
        }),
      );
      await buscarFreteTabeladoPorIdFilial(
        idFilial,
        setDadosVeiculosGrupos,
        dispatch,
      );
    })
    .catch(() => {
      setDadosVeiculosGrupos([]);
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro! Linha não existe.',
        }),
      );
    });
};

export const listaConfiguracaoColaboradoresNegociadores = async (
  setListaColaboradoresNegociadores: (
    dados: listaColaboradorNegociador[] | undefined,
  ) => void,
): Promise<void> => {
  try {
    const resposta = await manager.listaConfiguracaoColaboradoresNegociadores();
    if (resposta) {
      setListaColaboradoresNegociadores(resposta);
    }
  } catch (error: unknown) {
    setListaColaboradoresNegociadores(undefined);
  }
};

export const configuracaoColaboradoresNegociadores = async (
  idColaborador: number,
  setConfigColaboradorNegociador: (
    dados: ConfiguracoesColaboradorNegociador[] | [],
  ) => void,
): Promise<void> => {
  try {
    const resposta = await manager.configuracaoColaboradoresNegociadores(
      idColaborador,
    );

    if (resposta && Array.isArray(resposta)) {
      setConfigColaboradorNegociador(resposta);
    } else {
      setConfigColaboradorNegociador([]);
    }
  } catch (error: unknown) {
    setConfigColaboradorNegociador([]);
  }
};

export const salvarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .salvarConfiguracaoColaboradoresNegociadores(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Esquema de colaborador salvo com sucesso!',
        }),
      );
    })
    .catch(() => {
      // nenhum erro para tratar
    });
};

export const atualizarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .atualizarConfiguracaoColaboradoresNegociadores(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Esquema de colaborador salvo com sucesso!',
        }),
      );
    })
    .catch(() => {
      // nenhum erro para tratar
    });
};

export const apagarConfiguracaoColaboradoresNegociadores = async (
  idConfiguracaoColaborador: number,
  dispatch: Dispatch,
  ativarMensagem?: boolean,
): Promise<void> => {
  manager
    .apagarConfiguracaoColaboradoresNegociadores(idConfiguracaoColaborador)
    .then(async () => {
      if (ativarMensagem === true) {
        dispatch(
          abrirSnackbarSucesso({
            mensagem: 'Configuração excluida com sucesso!',
          }),
        );
      }
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Configuração atualizada com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao apagar!',
        }),
      );
    });
};

export const cadastrarMacroFiliais = async (
  request: MacroFilialRequest,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .cadastrarMacroFiliais(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Cadastro realizado com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao cadastrar!',
        }),
      );
    });
};

export const atualizarMacroFiliais = async (
  request: MacroFilialRequest,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .atualizarMacroFiliais(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Atualizaçao realizada com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao atualizar!',
        }),
      );
    });
};

export const buscarFiliais = async (
  setFiliais: (filiais: BuscarFiliaisResponse[]) => void,
  dispatch: Dispatch,
  consulta?: string,
): Promise<void> => {
  manager
    .buscarFiliais(consulta)
    .then(async (resposta) => {
      if (Array.isArray(resposta)) {
        setFiliais(resposta);
      }
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao buscar!',
        }),
      );
    });
};

export const buscarCidades = async (
  setCidades: (cidades: BuscarCidadesResponse) => void,
  paginaSelecionada: string,
  codigoIbge?: string,
  descricao?: string,
  isApresentarTodasCidades?: boolean,
): Promise<void> => {
  try {
    const resposta = await manager.buscarCidades(
      paginaSelecionada,
      codigoIbge,
      descricao,
      isApresentarTodasCidades,
    );
    setCidades(resposta);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const atualizarCidades = async (
  request: AtualizarCidadesRequest,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .atualizarCidades(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Atualizaçao realizada com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao atualizar!',
        }),
      );
    });
};

export const cadastrarCidades = async (
  request: CadastrarCidadesRequest,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .cadastrarCidades(request)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Cadastro realizado com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao cadastrar!',
        }),
      );
    });
};

export const buscarEstados = async (
  setEstados: (estados: EstadosResponse[]) => void,
  dispatch: Dispatch,
): Promise<void> => {
  try {
    const resposta = await manager.buscarEstados();
    if (Array.isArray(resposta)) {
      setEstados(resposta);
    }
  } catch (error) {
    dispatch(
      abrirSnackbarErro({
        mensagem: 'Erro ao buscar!',
      }),
    );
  }
};

export const postUploadCidades = async (
  arquivo: File,
  idMacro: string,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .postUploadCidades(arquivo, idMacro)
    .then(async () => {
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Upload planilha realizado com sucesso!',
        }),
      );
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao enviar planilha!',
        }),
      );
    });
};

export const atualizarAlgoritmoFilial = async (
  idFilial: string,
  ativar: boolean,
  dispatch: Dispatch,
): Promise<void> => {
  manager
    .atualizarAlgoritmoFilial(idFilial, ativar)
    .then(async () => {
      if (ativar) {
        dispatch(
          abrirSnackbarSucesso({
            mensagem: 'Filial ativada!',
          }),
        );
      } else {
        dispatch(
          abrirSnackbarSucesso({
            mensagem: 'Filial desativada!',
          }),
        );
      }
    })
    .catch(() => {
      dispatch(
        abrirSnackbarErro({
          mensagem: 'Erro ao atualizar!',
        }),
      );
    });
};
