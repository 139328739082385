import { IntervaloTempo } from 'shared/types/apiTypes';
import { ItemFilial } from 'features/listaCargas/api/listaCargasApiTypes';
import * as configuracoesService from './configuracoesService';
import {
  CategoriaVeiculo,
  DadosTabelaPrecificacoes,
  FreteTabeladoRegiao,
  FreteTabeladoMacroId,
  ParametrosTempo,
  PermissaoColaboradoresResponse,
  salvarPermissaoColaboradorRequest,
  ColaboradoresNegociadores,
  ConfiguracoesColaboradorNegociador,
  listaColaboradorNegociador,
  MacroFilialRequest,
  BuscarFiliaisResponse,
  BuscarCidadesResponse,
  AtualizarCidadesRequest,
  EstadosResponse,
  CadastrarCidadesRequest,
} from './configuracoesApiTypes';

export const obterPermissaoColaboradores = async (): Promise<PermissaoColaboradoresResponse> => {
  return configuracoesService.obterPermissaoColaboradores();
};

export const obterColaboradoresNegociadores = async (): Promise<ColaboradoresNegociadores> => {
  return configuracoesService.obterColaboradoresNegociadores();
};

export const salvarPermissaoColaborador = async (
  request: salvarPermissaoColaboradorRequest,
): Promise<void> => {
  return configuracoesService.salvarPermissaoColaborador(request);
};

export const obterParametrosTempo = async (): Promise<ParametrosTempo> => {
  return configuracoesService.obterParametrosTempo();
};

export const salvarParametrosTempo = async (
  sugestaoRoboHabilitada: boolean,
  isFreteTabeladoHabilitado: boolean,
  tempoLimiteSemVisualizarNegociacao: IntervaloTempo,
  tempoLimiteSemAprovacao: IntervaloTempo,
  tempoLimiteCargaPrioridadeMaxima: IntervaloTempo,
): Promise<void> => {
  return configuracoesService.salvarParametrosTempo({
    sugestaoRoboHabilitada,
    isFreteTabeladoHabilitado,
    tempoLimiteSemVisualizarNegociacao,
    tempoLimiteSemAprovacao,
    tempoLimiteCargaPrioridadeMaxima,
  });
};

export const salvarParametrosFilial = async (
  value: ItemFilial,
): Promise<void> => configuracoesService.salvarParametrosFilial(value);

export const buscarCategoriaVeiculosFreteTabelado = async (): Promise<CategoriaVeiculo> => {
  return configuracoesService.buscarCategoriaVeiculosFreteTabelado();
};

export const atualizarCategoriaVeiculoFreteTabelado = async (
  categoriaVeiculo: CategoriaVeiculo,
): Promise<void> =>
  configuracoesService.atualizarCategoriaVeiculoFreteTabelado(categoriaVeiculo);

export const buscarFreteTabeladoMacros = async (): Promise<FreteTabeladoRegiao> => {
  return configuracoesService.buscarFreteTabeladoMacros();
};

export const buscarFreteTabeladoPorIdMacro = async (
  idMacro: string,
): Promise<FreteTabeladoMacroId> => {
  return configuracoesService.buscarFreteTabeladoPorIdMacro(idMacro);
};

export const salvarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  return configuracoesService.salvarFreteTabeladoPorIdMacro(
    idMacro,
    idCategoriaVeiculo,
    request,
  );
};

export const atualizarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  return configuracoesService.atualizarFreteTabeladoPorIdMacro(
    idMacro,
    idCategoriaVeiculo,
    request,
  );
};

export const deleteFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
): Promise<void> => {
  return configuracoesService.deleteFreteTabeladoPorIdMacro(
    idMacro,
    idCategoriaVeiculo,
  );
};

export const buscarFreteTabeladoPorIdFilial = async (
  idFilial: string,
): Promise<FreteTabeladoMacroId> => {
  return configuracoesService.buscarFreteTabeladoPorIdFilial(idFilial);
};

export const buscarFreteTabeladoMacroFilial = async (
  idFilial: string,
): Promise<FreteTabeladoRegiao> => {
  return configuracoesService.buscarFreteTabeladoMacroFilial(idFilial);
};

export const salvarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  return configuracoesService.salvarFreteTabeladoPorMacroFilial(
    idFilial,
    idCategoriaVeiculo,
    request,
  );
};

export const atualizarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  return configuracoesService.atualizarFreteTabeladoPorMacroFilial(
    idFilial,
    idCategoriaVeiculo,
    request,
  );
};

export const deleteFreteTabeladoPorMacroFilial = async (
  idMacro: string,
  idCategoriaVeiculo: string,
): Promise<void> => {
  return configuracoesService.deleteFreteTabeladoPorMacroFilial(
    idMacro,
    idCategoriaVeiculo,
  );
};

export const listaConfiguracaoColaboradoresNegociadores = async (): Promise<
  listaColaboradorNegociador[]
> => {
  return configuracoesService.listaConfiguracaoColaboradoresNegociadores();
};

export const configuracaoColaboradoresNegociadores = async (
  idColaborador: number,
): Promise<ConfiguracoesColaboradorNegociador> => {
  return configuracoesService.configuracaoColaboradoresNegociadores(
    idColaborador,
  );
};

export const salvarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
): Promise<void> => {
  return configuracoesService.salvarConfiguracaoColaboradoresNegociadores(
    request,
  );
};

export const atualizarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
): Promise<void> => {
  return configuracoesService.atualizarConfiguracaoColaboradoresNegociadores(
    request,
  );
};

export const apagarConfiguracaoColaboradoresNegociadores = async (
  idConfiguracaoColaborador: number,
): Promise<void> => {
  return configuracoesService.apagarConfiguracaoColaboradoresNegociadores(
    idConfiguracaoColaborador,
  );
};

export const cadastrarMacroFiliais = async (
  request: MacroFilialRequest,
): Promise<void> => {
  return configuracoesService.cadastrarMacroFiliais(request);
};

export const atualizarMacroFiliais = async (
  request: MacroFilialRequest,
): Promise<void> => {
  return configuracoesService.atualizarMacroFiliais(request);
};

export const buscarFiliais = async (
  consulta?: string,
): Promise<BuscarFiliaisResponse> => {
  return configuracoesService.buscarFiliais(consulta);
};

export const buscarCidades = async (
  paginaSelecionada: string,
  codigoIbge?: string,
  descricao?: string,
  isApresentarTodasCidades?: boolean,
): Promise<BuscarCidadesResponse> => {
  return configuracoesService.buscarCidades(
    paginaSelecionada,
    codigoIbge,
    descricao,
    isApresentarTodasCidades,
  );
};

export const atualizarCidades = async (
  request: AtualizarCidadesRequest,
): Promise<void> => {
  return configuracoesService.atualizarCidades(request);
};

export const cadastrarCidades = async (
  request: CadastrarCidadesRequest,
): Promise<void> => {
  return configuracoesService.cadastrarCidades(request);
};

export const buscarEstados = async (): Promise<EstadosResponse> => {
  return configuracoesService.buscarEstados();
};

export const postUploadCidades = async (
  arquivo: File,
  idMacro: string,
): Promise<void> => {
  return configuracoesService.postUploadCidades(arquivo, idMacro);
};

export const atualizarAlgoritmoFilial = async (
  idFilial: string,
  ativar: boolean,
): Promise<void> => {
  return configuracoesService.atualizarAlgoritmoFilial(idFilial, ativar);
};
