import React from 'react';
import { useStyles } from 'features/configuracoes/styles/styleEsquemaColaborador';
import { Box, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  ColaboradoresNegociadores,
  listaColaboradorNegociador,
} from 'features/configuracoes/api/configuracoesApiTypes';

interface PropsListaColaboradoresConfigurados {
  listaColaboradoresNegociadores: listaColaboradorNegociador[] | undefined;
  selecionarColaboradorEdit: (
    id: number,
    nome: string,
    configuracoesIds: number[],
  ) => void;
  selectedColaborador: ColaboradoresNegociadores[];
}

const ListaColaboradoresConfigurados = ({
  listaColaboradoresNegociadores,
  selecionarColaboradorEdit,
  selectedColaborador,
}: PropsListaColaboradoresConfigurados): JSX.Element => {
  const classes = useStyles();
  const idsUnicos = new Set();
  const configuracaoIds = new Set<number>();

  const colaboradoresUnicos =
    listaColaboradoresNegociadores &&
    listaColaboradoresNegociadores.filter((item) => {
      configuracaoIds.add(item.configuracaoId);
      if (idsUnicos.has(item.colaborador.idColaborador)) {
        return false;
      }
      idsUnicos.add(item.colaborador.idColaborador);
      return true;
    });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Box display="flex" gridGap="5px">
        <Typography className={classes.tituloInput}>
          Esquema de colaboradores configurados:
        </Typography>
        {listaColaboradoresNegociadores &&
          listaColaboradoresNegociadores?.length > 0 && (
            <CheckCircleOutlineIcon className={classes.iconCheck} />
          )}
      </Box>
      {colaboradoresUnicos && colaboradoresUnicos.length > 0 ? (
        <Box className={classes.containerChips}>
          {colaboradoresUnicos.map((item) => {
            const isSelected = selectedColaborador.some(
              (colaborador) =>
                colaborador.id === item.colaborador.idColaborador,
            );
            return (
              <Box
                key={item.colaborador.idColaborador}
                onClick={() =>
                  selecionarColaboradorEdit(
                    item.colaborador.idColaborador,
                    item.colaborador.nome,
                    Array.from(configuracaoIds),
                  )
                }
                className={
                  isSelected ? classes.chipFilialSelected : classes.chipFilial
                }
              >
                <Typography className={classes.textoChipFilial}>
                  {item.colaborador.nome}
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box className={classes.containerListFiliais}>
          <BlockIcon
            color="disabled"
            style={{ width: '100px', height: '100px' }}
          />
          <Typography className={classes.tituloBlock}>
            Ainda não foi configurado nenhum esquema de horário
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ListaColaboradoresConfigurados;
