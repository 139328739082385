import { ItemFilial } from 'features/listaCargas/api/listaCargasApiTypes';
import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import {
  PermissaoColaboradoresResponse,
  salvarPermissaoColaboradorRequest,
  ParametrosTempo,
  CategoriaVeiculo,
  FreteTabeladoRegiao,
  FreteTabeladoMacroId,
  DadosPrecificacoes,
  ColaboradoresNegociadores,
  DadosTabelaPrecificacoes,
  ConfiguracoesColaboradorNegociador,
  listaColaboradorNegociador,
  MacroFilialRequest,
  BuscarFiliaisResponse,
  BuscarCidadesResponse,
  AtualizarCidadesRequest,
  CadastrarCidadesRequest,
  EstadosResponse,
} from './configuracoesApiTypes';

const obterUrlBuscarPermissaoColaboradores = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/colaboradores/permissoes`;

export const obterPermissaoColaboradores = async (): Promise<PermissaoColaboradoresResponse> => {
  const url = obterUrlBuscarPermissaoColaboradores();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<PermissaoColaboradoresResponse>();
  const response = await conectar();

  return response.data;
};

const obterUrlSalvarPermissaoColaborador = (idColaborador: number): string =>
  `${process.env.REACT_APP_URL_API_BASE}/colaboradores/idColaborador/permissoes`.replace(
    'idColaborador',
    idColaborador.toString(),
  );

const obterUrlColaboradoresNegociadores = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores`;

export const obterColaboradoresNegociadores = async (): Promise<ColaboradoresNegociadores> => {
  const url = obterUrlColaboradoresNegociadores();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<ColaboradoresNegociadores>();
  const response = await conectar();

  return response.data;
};

export const salvarPermissaoColaborador = async ({
  idColaborador,
  matricula,
  acessoConfiguracaoPerfil,
  acessoNegociacaoCargas,
  percentualMaximoAprovacao,
  acessoFreteTabelado,
  acessoSimuladorPlanilha,
  acessoTorreControle,
  roles,
  ativo,
}: salvarPermissaoColaboradorRequest): Promise<void> => {
  const url = obterUrlSalvarPermissaoColaborador(idColaborador);
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody({
    matricula,
    acessoConfiguracaoPerfil,
    acessoNegociacaoCargas,
    percentualMaximoAprovacao,
    acessoFreteTabelado,
    acessoUploadExcelCargaSimulador: acessoSimuladorPlanilha,
    acessoTorreControle,
    roles,
    ativo,
  });
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<PermissaoColaboradoresResponse>();
  await conectar();
};

const obterUrlParametrosTempo = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/sistema/parametros`;

const obterUrlParametrosFilial = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/filiais`;

export const obterParametrosTempo = async (): Promise<ParametrosTempo> => {
  const url = obterUrlParametrosTempo();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<ParametrosTempo>();
  const response = await conectar();
  return response.data;
};

export const salvarParametrosTempo = async (
  parametrosTempo: ParametrosTempo,
): Promise<void> => {
  const url = obterUrlParametrosTempo();
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody(parametrosTempo);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const salvarParametrosFilial = async (
  values: ItemFilial,
): Promise<void> => {
  const url = obterUrlParametrosFilial();
  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody({
    filial: {
      idFilial: values.id,
      integracaoRoboAtivo: values.integracaoRoboAtivo,
      percentualFrotaPropria: values.percentualFrotaPropria / 100,
    },
  });
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

const categoriaVeiculosFreteTabelado = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/categoria-veiculo`;

export const buscarCategoriaVeiculosFreteTabelado = async (): Promise<CategoriaVeiculo> => {
  const url = categoriaVeiculosFreteTabelado();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<CategoriaVeiculo>();
  const response = await conectar();
  return response.data;
};

export const atualizarCategoriaVeiculoFreteTabelado = async (
  categoriaVeiculo: CategoriaVeiculo,
): Promise<void> => {
  const url = categoriaVeiculosFreteTabelado();
  const conexaoApi = new ConexaoAPI(url, 'patch');
  const requestBody = {
    categorias: [
      {
        idCategoriaVeiculo: categoriaVeiculo.idCategoriaVeiculo,
        descricao: categoriaVeiculo.descricao,
        capacidadeMaximaPeso: categoriaVeiculo.capacidadeMaximaPeso,
        capacidadeMinimaPeso: categoriaVeiculo.capacidadeMinimaPeso,
        volumetria: categoriaVeiculo.volumetria,
      },
    ],
  };
  conexaoApi.adicionarBody(requestBody);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

const freteTabeladoMacros = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/frete-tabelado/macros`;

export const buscarFreteTabeladoMacros = async (): Promise<FreteTabeladoRegiao> => {
  const url = freteTabeladoMacros();
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<FreteTabeladoRegiao>();
  const response = await conectar();
  return response.data;
};

const freteTabeladoRegiaoIdMacro = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/frete-tabelado/macro`;

export const buscarFreteTabeladoPorIdMacro = async (
  idMacro: string,
): Promise<FreteTabeladoMacroId> => {
  const url = `${freteTabeladoRegiaoIdMacro()}/${idMacro}`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<FreteTabeladoMacroId>();
  const response = await conectar();
  return response.data;
};

export const salvarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  const url = `${freteTabeladoRegiaoIdMacro()}/${idMacro}/categoria-veiculo/${idCategoriaVeiculo}`;
  const precificacoes = request.map((item) => ({
    componente: item.componente,
    de: item.de,
    ate: item.ate,
    deKm: item.deKm,
    ateKm: item.ateKm,
    valorFixo: item.valorFixo,
    valorPF: item.valorPF,
    valorPJ: item.valorPJ,
    valorKM: item.valorKM,
    valorKG: item.valorKG,
    valorPedagio: item.valorPedagio,
    valorReentrega: item.valorReentrega,
    valorDevolucao: item.valorDevolucao,
    possuiExcedente: item.possuiExcedente,
    valorExcedente: item.valorExcedente,
    possuiCargaEspecial: item.possuiCargaEspecial,
    cargaEspecial: item.cargaEspecial,
  }));

  const requestBody = {
    tempoParaExpirarNegociacoesEmMinutos:
      request[0]?.tempoParaExpirarNegociacoesEmMinutos,
    precificacoes,
  };
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(requestBody);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const atualizarFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  const url = `${freteTabeladoRegiaoIdMacro()}/${idMacro}/categoria-veiculo/${idCategoriaVeiculo}`;
  const precificacoes = request.map((item) => ({
    componente: item.componente,
    de: item.de,
    ate: item.ate,
    deKm: item.deKm,
    ateKm: item.ateKm,
    valorFixo: item.valorFixo,
    valorPF: item.valorPF,
    valorPJ: item.valorPJ,
    valorKM: item.valorKM,
    valorKG: item.valorKG,
    valorPedagio: item.valorPedagio,
    valorReentrega: item.valorReentrega,
    valorDevolucao: item.valorDevolucao,
    possuiExcedente: item.possuiExcedente,
    valorExcedente: item.valorExcedente,
    possuiCargaEspecial: item.possuiCargaEspecial,
    cargaEspecial: item.cargaEspecial,
  }));

  const requestBody = {
    tempoParaExpirarNegociacoesEmMinutos:
      request[0]?.tempoParaExpirarNegociacoesEmMinutos,
    precificacoes,
  };
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody(requestBody);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const deleteFreteTabeladoPorIdMacro = async (
  idMacro: string,
  idCategoriaVeiculo: string,
): Promise<void> => {
  const url = `${freteTabeladoRegiaoIdMacro()}/${idMacro}/categoria-veiculo/${idCategoriaVeiculo}`;
  const conexaoApi = new ConexaoAPI(url, 'delete');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

const freteTabeladoFilial = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/frete-tabelado/filial`;

export const buscarFreteTabeladoPorIdFilial = async (
  idFilial: string,
): Promise<FreteTabeladoMacroId> => {
  const url = `${freteTabeladoFilial()}/${idFilial}`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<FreteTabeladoMacroId>();
  const response = await conectar();
  return response.data;
};

const freteTabeladoMacroFilial = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/frete-tabelado/macro/filial`;

export const buscarFreteTabeladoMacroFilial = async (
  idFilial: string,
): Promise<FreteTabeladoRegiao> => {
  const url = `${freteTabeladoMacroFilial()}/${idFilial}`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<FreteTabeladoRegiao>();
  const response = await conectar();
  return response.data;
};

export const salvarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  const url = `${freteTabeladoFilial()}/${idFilial}/categoria-veiculo/${idCategoriaVeiculo}`;
  const precificacoes = request.map((item) => ({
    componente: item.componente,
    de: item.de,
    ate: item.ate,
    deKm: item.deKm,
    ateKm: item.ateKm,
    valorFixo: item.valorFixo,
    valorPF: item.valorPF,
    valorPJ: item.valorPJ,
    valorKM: item.valorKM,
    valorKG: item.valorKG,
    valorPedagio: item.valorPedagio,
    valorReentrega: item.valorReentrega,
    valorDevolucao: item.valorDevolucao,
    possuiExcedente: item.possuiExcedente,
    valorExcedente: item.valorExcedente,
    possuiCargaEspecial: item.possuiCargaEspecial,
    cargaEspecial: item.cargaEspecial,
  }));

  const requestBody = {
    tempoParaExpirarNegociacoesEmMinutos:
      request[0]?.tempoParaExpirarNegociacoesEmMinutos,
    precificacoes,
  };
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(requestBody);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const atualizarFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
  request: DadosTabelaPrecificacoes[],
): Promise<void> => {
  const url = `${freteTabeladoFilial()}/${idFilial}/categoria-veiculo/${idCategoriaVeiculo}`;
  const precificacoes = request.map((item) => ({
    componente: item.componente,
    de: item.de,
    ate: item.ate,
    deKm: item.deKm,
    ateKm: item.ateKm,
    valorFixo: item.valorFixo,
    valorPF: item.valorPF,
    valorPJ: item.valorPJ,
    valorKM: item.valorKM,
    valorKG: item.valorKG,
    valorPedagio: item.valorPedagio,
    valorReentrega: item.valorReentrega,
    valorDevolucao: item.valorDevolucao,
    possuiExcedente: item.possuiExcedente,
    valorExcedente: item.valorExcedente,
    possuiCargaEspecial: item.possuiCargaEspecial,
    cargaEspecial: item.cargaEspecial,
  }));

  const requestBody = {
    tempoParaExpirarNegociacoesEmMinutos:
      request[0]?.tempoParaExpirarNegociacoesEmMinutos,
    precificacoes,
  };
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody(requestBody);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const deleteFreteTabeladoPorMacroFilial = async (
  idFilial: string,
  idCategoriaVeiculo: string,
): Promise<void> => {
  const url = `${freteTabeladoFilial()}/${idFilial}/categoria-veiculo/${idCategoriaVeiculo}`;
  const conexaoApi = new ConexaoAPI(url, 'delete');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const listaConfiguracaoColaboradoresNegociadores = async (): Promise<
  listaColaboradorNegociador[]
> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores/configurar`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<
    listaColaboradorNegociador[]
  >();
  const response = await conectar();
  return response.data;
};

const urlConfiguracaoColaboradoresNegociadores = (
  idColaborador: number,
): string =>
  `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores/${idColaborador}/configurar`;

export const configuracaoColaboradoresNegociadores = async (
  idColaborador: number,
): Promise<ConfiguracoesColaboradorNegociador> => {
  const url = `${urlConfiguracaoColaboradoresNegociadores(idColaborador)}`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<ConfiguracoesColaboradorNegociador>();
  const response = await conectar();
  return response.data;
};

export const salvarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores/configurar`;
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const atualizarConfiguracaoColaboradoresNegociadores = async (
  request: ConfiguracoesColaboradorNegociador,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores/configurar`;
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<DadosPrecificacoes>();
  await conectar();
};

export const apagarConfiguracaoColaboradoresNegociadores = async (
  idConfiguracaoColaborador: number,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/colaboradores/negociadores/configurar`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ idConfiguracaoColaborador }),
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }
};

const urlMacroFiliais = (): string =>
  `${process.env.REACT_APP_URL_API_BASE}/macro-filiais`;

export const cadastrarMacroFiliais = async (
  request: MacroFilialRequest,
): Promise<void> => {
  const url = `${urlMacroFiliais()}`;
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const atualizarMacroFiliais = async (
  request: MacroFilialRequest,
): Promise<void> => {
  const url = `${urlMacroFiliais()}`;
  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const buscarFiliais = async (
  consulta?: string,
): Promise<BuscarFiliaisResponse> => {
  let url = `${process.env.REACT_APP_URL_API_BASE}/filiais/consulta-simplificada`;

  if (consulta) {
    url += `?consulta=${encodeURIComponent(consulta)}`;
  }

  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<BuscarFiliaisResponse>();
  const response = await conectar();

  return response.data;
};

export const buscarCidades = async (
  paginaSelecionada: string,
  codigoIbge?: string,
  descricao?: string,
  isApresentarTodasCidades?: boolean,
): Promise<BuscarCidadesResponse> => {
  const codigoIbgeParam = codigoIbge ? `&CodigoIbge=${codigoIbge}` : '';
  const descricaoParam = descricao
    ? `&Descricao=${encodeURIComponent(descricao)}`
    : '';
  const isApresentarTodasCidadesParam =
    isApresentarTodasCidades !== undefined
      ? `&IsApresentarTodasCidades=${isApresentarTodasCidades}`
      : '';

  const url = `${process.env.REACT_APP_URL_API_BASE}/cidades/get-cidades?Pagina=${paginaSelecionada}&ItensPorPagina=99${codigoIbgeParam}${descricaoParam}${isApresentarTodasCidadesParam}`;

  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<BuscarCidadesResponse>();
  const response = await conectar();
  return response.data;
};

export const atualizarCidades = async (
  request: AtualizarCidadesRequest,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/cidades`;
  const conexaoApi = new ConexaoAPI(url, 'patch');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const cadastrarCidades = async (
  request: CadastrarCidadesRequest,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/cidades`;
  const conexaoApi = new ConexaoAPI(url, 'post');
  conexaoApi.adicionarBody(request);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};

export const buscarEstados = async (): Promise<EstadosResponse> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/estados`;
  const conexaoApi = new ConexaoAPI(url, 'get');
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<EstadosResponse>();
  const response = await conectar();
  return response.data;
};

export const postUploadCidades = async (
  arquivo: File,
  idMacro: string,
): Promise<void> => {
  const formData = new FormData();

  formData.append('arquivo', arquivo);

  const URL = `${process.env.REACT_APP_URL_API_BASE}/cidades/vincular-macro?macroId=${idMacro}`;
  const conexao = new ConexaoAPI(URL, 'post');
  conexao.adicionarBody(formData);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const atualizarAlgoritmoFilial = async (
  idFilial: string,
  ativar: boolean,
): Promise<void> => {
  const url = `${process.env.REACT_APP_URL_API_BASE}/filiais/${idFilial}`;
  const conexaoApi = new ConexaoAPI(url, 'put');
  conexaoApi.adicionarBody(ativar);
  const conectar = conexaoApi.gerarFuncaoConectarRefreshToken<void>();
  await conectar();
};
