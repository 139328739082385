/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useStyles } from 'features/configuracoes/styles/styleEsquemaColaborador';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  ColaboradoresNegociadores,
  ConfiguracoesColaboradorNegociador,
  listaColaboradorNegociador,
} from 'features/configuracoes/api/configuracoesApiTypes';
import {
  apagarConfiguracaoColaboradoresNegociadores,
  atualizarConfiguracaoColaboradoresNegociadores,
  buscarColaboradoresNegociadores,
  configuracaoColaboradoresNegociadores,
  listaConfiguracaoColaboradoresNegociadores,
  salvarConfiguracaoColaboradoresNegociadores,
} from 'features/configuracoes/utils/configuracoes';
import {
  formatarHorarioHorasMinutos,
  processarHoras,
  removeAccents,
} from 'features/configuracoes/utils/inputIntervaloTempo';
import {
  abrirSnackbarErro,
  abrirSnackbarSucesso,
} from 'shared/components/snackbar/redux/snackbarAction';
import ConteudoEditEsquemaColaborador from './ConteudoEditEsquemaColaborador';
import ListaColaboradoresConfigurados from './ListaColaboradoresConfigurados';

type DiasSelecionados = {
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  domingo: boolean;
  [key: string]: boolean;
};

const ContainerEsquemaColaboradores = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    colaboradores,
    setColaboradores,
  ] = useState<ColaboradoresNegociadores>();
  const [
    configColaboradorNegociador,
    setConfigColaboradorNegociador,
  ] = useState<ConfiguracoesColaboradorNegociador[]>([]);

  const [
    listaColaboradoresNegociadores,
    setListaColaboradoresNegociadores,
  ] = useState<listaColaboradorNegociador[] | undefined>();
  const [selectedColaborador, setSelectedColaborador] = useState<
    ColaboradoresNegociadores[]
  >([]);

  const [horarioInicio, setHorarioInicio] = useState<string>('00h 00');
  const [horarioFim, setHorarioFim] = useState<string>('00h 00');
  const [erroHorario, setErroHorario] = useState<boolean>(false);
  const [diasSelecionados, setDiasSelecionados] = useState<DiasSelecionados>({
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarColaboradoresNegociadores(setColaboradores, dispatch);
  }, [dispatch]);

  const listaColaboradoresConfigurados = () => {
    listaConfiguracaoColaboradoresNegociadores(
      setListaColaboradoresNegociadores,
    );
  };

  useEffect(() => {
    listaColaboradoresConfigurados();
  }, []);

  useEffect(() => {
    if (selectedColaborador) {
      configuracaoColaboradoresNegociadores(
        selectedColaborador[0]?.id,
        setConfigColaboradorNegociador,
      );
    }
  }, [dispatch, selectedColaborador]);

  useEffect(() => {
    if (configColaboradorNegociador && configColaboradorNegociador.length > 0) {
      let horarioInicio = '';
      let horarioFim = '';
      const diasSelecionados = {
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
      };

      configColaboradorNegociador.forEach((element) => {
        horarioInicio =
          formatarHorarioHorasMinutos(element.horarioInicio) || horarioInicio;
        horarioFim =
          formatarHorarioHorasMinutos(element.horarioFim) || horarioFim;

        switch (element.diaSemanaTrabalhado.toLowerCase()) {
          case 'monday':
            diasSelecionados.segunda = true;
            break;
          case 'tuesday':
            diasSelecionados.terca = true;
            break;
          case 'wednesday':
            diasSelecionados.quarta = true;
            break;
          case 'thursday':
            diasSelecionados.quinta = true;
            break;
          case 'friday':
            diasSelecionados.sexta = true;
            break;
          case 'saturday':
            diasSelecionados.sabado = true;
            break;
          case 'sunday':
            diasSelecionados.domingo = true;
            break;
          default:
            break;
        }
      });

      setHorarioInicio(horarioInicio);
      setHorarioFim(horarioFim);
      setDiasSelecionados(diasSelecionados);
    } else {
      setHorarioInicio('');
      setHorarioFim('');
      setDiasSelecionados({
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
      });
    }
  }, [configColaboradorNegociador]);

  const handleChangeHorarios = (
    e: { target: { value: string } },
    setHorario: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const horas = processarHoras(e.target.value);

    if (horas && horas.length > 0) {
      const horasPart = horas[0];
      const minutosPart = horas[1];
      const horasValidas =
        parseInt(horasPart, 10) >= 0 && parseInt(horasPart, 10) <= 23;
      const minutosValidos =
        parseInt(minutosPart, 10) >= 0 && parseInt(minutosPart, 10) <= 59;

      setErroHorario(!(horasValidas && minutosValidos));
    }
    if (horas) {
      const horasFormatadas = horas.join('h ');
      setHorario(horasFormatadas);
    } else {
      setHorario('');
    }
  };

  const handleChangeHorarioInicio = (e: { target: { value: string } }) => {
    handleChangeHorarios(e, setHorarioInicio);
  };

  const handleChangeHorarioFim = (e: { target: { value: string } }) => {
    handleChangeHorarios(e, setHorarioFim);
  };

  const limparCampos = () => {
    setSelectedColaborador([]);
    setErroHorario(false);
    setHorarioFim('');
    setHorarioInicio('');
    setDiasSelecionados({
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
      domingo: false,
    });
    listaColaboradoresConfigurados();
  };
  const diasDaSemanaMap: Record<string, string> = {
    segunda: 'monday',
    terca: 'tuesday',
    quarta: 'wednesday',
    quinta: 'thursday',
    sexta: 'friday',
    sabado: 'saturday',
    domingo: 'sunday',
  };
  // console.log(diasSelecionados);
  // console.log(configColaboradorNegociador);

  const handleSalvarConfig = async () => {
    function formatarHorarioParaEnvio(horario: string) {
      const partes = horario.split(' ');
      const hora = partes[0].replace('h', ':');
      const minutos = partes[1];

      // Formatar para HH:mm:ss
      const horaFormatada = `${hora}${minutos}:00`;
      return horaFormatada;
    }

    // Array de dias da semana em inglês

    setLoading(true);

    try {
      // eslint-disable-next-line no-restricted-syntax
      const horarioInicioConvertido = formatarHorarioParaEnvio(horarioInicio);
      const horarioFimConvertido = formatarHorarioParaEnvio(horarioFim);

      const existeConfig = configColaboradorNegociador.find((config) =>
        selectedColaborador[0]?.idConfiguracao?.includes(
          Number(config.idConfiguracaoColaborador),
        ),
      );

      const diasTrabalhados = configColaboradorNegociador.map(
        (config) => config.diaSemanaTrabalhado,
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const config of configColaboradorNegociador) {
        const diaEmIngles = config.diaSemanaTrabalhado;
        const dayEnglish = Object.keys(diasDaSemanaMap).find(
          (key) => diasDaSemanaMap[key] === diaEmIngles,
        );
        if (
          dayEnglish &&
          !diasSelecionados[dayEnglish] &&
          diasSelecionados[dayEnglish] !== undefined
        ) {
          // eslint-disable-next-line no-await-in-loop
          await apagarConfiguracaoColaboradoresNegociadores(
            Number(config.idConfiguracaoColaborador),
            dispatch,
            false,
          );
          limparCampos();
        }
        dispatch(
          abrirSnackbarSucesso({
            mensagem: 'Esquema de colaborador salvo com sucesso!',
          }),
        );
      }

      const diasFaltando = Object.keys(diasSelecionados)
        .filter(
          (dia) =>
            diasSelecionados[dia] &&
            !diasTrabalhados.includes(diasDaSemanaMap[dia]),
        )
        .map((dia) => diasDaSemanaMap[dia]);

      if (!existeConfig || diasFaltando.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const dia of diasFaltando) {
          const requestPost: ConfiguracoesColaboradorNegociador = {
            idColaborador: selectedColaborador[0]?.id,
            horarioInicio: horarioInicioConvertido,
            horarioFim: horarioFimConvertido,
            diaSemanaTrabalhado: dia,
          };
          // eslint-disable-next-line no-await-in-loop
          await salvarConfiguracaoColaboradoresNegociadores(
            requestPost,
            dispatch,
          );
        }
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const idConfiguracao of configColaboradorNegociador) {
          const requestPut: ConfiguracoesColaboradorNegociador = {
            horarioInicio: horarioInicioConvertido,
            horarioFim: horarioFimConvertido,
            diaSemanaTrabalhado: idConfiguracao.diaSemanaTrabalhado,
            idConfiguracaoColaborador: idConfiguracao.idConfiguracaoColaborador,
          };

          // eslint-disable-next-line no-await-in-loop
          await atualizarConfiguracaoColaboradoresNegociadores(
            requestPut,
            dispatch,
          );
        }
      }
      dispatch(
        abrirSnackbarSucesso({
          mensagem: 'Esquema de colaborador salvo com sucesso!',
        }),
      );

      limparCampos();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Erro ao salvar configuração:', error);
    } finally {
      setLoading(false);
      listaColaboradoresConfigurados();
    }
  };

  const apagarColaborador = async () => {
    setLoading(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const item of configColaboradorNegociador) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await apagarConfiguracaoColaboradoresNegociadores(
          Number(item.idConfiguracaoColaborador),
          dispatch,
          true,
        );
      } catch (error) {
        dispatch(
          abrirSnackbarErro({
            mensagem: 'Erro ao excluir!',
          }),
        );
      }
    }
    limparCampos();
    setLoading(false);
    setListaColaboradoresNegociadores([]);
  };

  const handleDiaSelecionado = (dia: string) => {
    const diaFormatado = removeAccents(dia.toLowerCase());

    setDiasSelecionados((prevDiasSelecionados) => ({
      ...prevDiasSelecionados,
      [diaFormatado as keyof DiasSelecionados]: !prevDiasSelecionados[
        diaFormatado as keyof DiasSelecionados
      ],
    }));
  };

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    newValue: ColaboradoresNegociadores[] | [],
  ) => {
    if (Array.isArray(newValue) && newValue.length > 0) {
      const recentItem = newValue[newValue.length - 1];
      setSelectedColaborador([recentItem]);
    } else {
      setSelectedColaborador([]);
    }
  };

  const selecionarColaboradorEdit = (
    idColaborador: number,
    nome: string,
    idConfiguracao?: number[],
  ) => {
    const colaborador: ColaboradoresNegociadores = {
      id: idColaborador,
      nome,
      idConfiguracao,
    };
    setSelectedColaborador([colaborador]);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.container}>
        <Box className={classes.coluna1}>
          <ConteudoEditEsquemaColaborador
            dadosColaboradores={colaboradores}
            selectedColaborador={selectedColaborador}
            horarioInicio={horarioInicio}
            horarioFim={horarioFim}
            erroHorario={erroHorario}
            diasSelecionados={diasSelecionados}
            handleChangeHorarioInicio={handleChangeHorarioInicio}
            handleChangeHorarioFim={handleChangeHorarioFim}
            handleAutocompleteChange={handleAutocompleteChange}
            handleDiaSelecionado={handleDiaSelecionado}
          />
        </Box>
        <Box className={classes.coluna2}>
          <ListaColaboradoresConfigurados
            listaColaboradoresNegociadores={listaColaboradoresNegociadores}
            selecionarColaboradorEdit={selecionarColaboradorEdit}
            selectedColaborador={selectedColaborador}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        gridGap="15px"
        marginTop="10px"
        marginRight="30px"
        justifyContent="space-between"
      >
        <Button
          onClick={apagarColaborador}
          disabled={
            !listaColaboradoresNegociadores?.find(
              (item) =>
                item?.colaborador?.idColaborador === selectedColaborador[0]?.id,
            ) ||
            horarioInicio >= horarioFim ||
            erroHorario
          }
          className={classes.buttonApagar}
        >
          Apagar
        </Button>
        <Box display="flex" gridGap="10px" alignItems="center">
          <Button
            onClick={limparCampos}
            className={classes.buttonLimpar}
            disabled={selectedColaborador.length === 0}
          >
            Limpar
          </Button>
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Button
              onClick={handleSalvarConfig}
              variant="contained"
              disabled={
                !horarioFim ||
                !horarioInicio ||
                !Object.values(diasSelecionados).some(
                  (valor) => valor === true,
                ) ||
                horarioInicio >= horarioFim ||
                selectedColaborador.length === 0 ||
                erroHorario
              }
              className={classes.buttonSalvar}
            >
              Salvar
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerEsquemaColaboradores;
