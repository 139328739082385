import { IntervaloTempo } from 'shared/types/apiTypes';
import { RoleType } from '../../../shared/constants/RoleColaborador';

export type DadosPermissaoColaborador = {
  idColaborador: number;
  matricula: number;
  nome: string;
  acessoConfiguracaoPerfil: boolean;
  acessoNegociacaoCargas: boolean;
  acessoTorreControle: boolean;
  acessoFreteTabelado: boolean;
  acessoSimuladorPlanilha: boolean;
  percentualMaximoAprovacao?: number;
  roles: RoleType[];
  ativo: boolean;
};

export type PermissaoColaboradoresResponse = {
  dadosPermissao: DadosPermissaoColaborador[];
};

export type ColaboradoresNegociadores = {
  id: number;
  nome: string;
  idConfiguracao?: number[];
};

export type salvarPermissaoColaboradorRequest = {
  idColaborador: number;
  matricula: number;
  acessoConfiguracaoPerfil: boolean;
  acessoNegociacaoCargas: boolean;
  percentualMaximoAprovacao: number;
  acessoTorreControle: boolean;
  acessoFreteTabelado: boolean;
  acessoSimuladorPlanilha: boolean;
  roles: RoleType[];
  ativo: boolean;
};

export type ParametrosTempo = {
  sugestaoRoboHabilitada: boolean;
  isFreteTabeladoHabilitado: boolean;
  tempoLimiteSemVisualizarNegociacao: IntervaloTempo;
  tempoLimiteSemAprovacao: IntervaloTempo;
  tempoLimiteCargaPrioridadeMaxima: IntervaloTempo;
};

export type CategoriaVeiculo = {
  idCategoriaVeiculo: number;
  descricao: string;
  capacidadeMaximaPeso: number | string;
  capacidadeMinimaPeso: number | string;
  volumetria: number | string;
};

export type FreteTabeladoRegiao = {
  id?: number;
  idMacroFilial: number;
  idFilial: number;
  descricao: string;
  possuiFreteTabeladoRegiao: boolean;
  possuiFreteTabeladoGrupo: boolean;
  descricaoFilial?: string;
};

export type FreteTabeladoMacroId = {
  idFreteTabelado: number;
  idFilial: number;
  idMacro: number;
  tempoParaExpirarNegociacoesEmMinutos: number;
  fretes: Fretes[];
};

export type Fretes = {
  categoriaVeiculo: {
    idCategoriaVeiculo: number;
    descricao: string;
    capacidadeMaximaPeso: number;
    capacidadeMinimaPeso: number;
    volumetria: number;
  };
  precificacoes: DadosTabelaPrecificacoes[];
};

export type DadosPrecificacoes = {
  tempoParaExpirarNegociacoesEmMinutos?: number;
  precificacoes: DadosTabelaPrecificacoes[];
};

export type DadosTabelaPrecificacoes = {
  tempoParaExpirarNegociacoesEmMinutos?: number;
  id?: number;
  idPrecificacaoFreteTabelado?: number;
  componente: EnumComponente;
  de: number | null;
  ate: number | null;
  deKm: number | null;
  ateKm: number | null;
  valorFixo: number | null;
  valorPF: number | null;
  valorPJ: number | null;
  valorKM: number | null;
  valorKG: number | null;
  valorPedagio: number | null;
  valorReentrega: number | null;
  valorDevolucao: number | null;
  possuiExcedente: boolean | null;
  valorExcedente: number | null | string;
  possuiCargaEspecial: boolean | null;
  cargaEspecial: EnumCargaEspecial | null;
};

export type ResultNovaLinhaDadosPrecificacoes = {
  de: number | null;
  ate: number | null;
  deKm: number | null;
  ateKm: number | null;
  valorFixo: number | null;
  valorPF: number | null;
  valorPJ: number | null;
  valorKM: number | null;
  valorKG: number | null;
  valorPedagio: number | null;
  valorReentrega: number | null;
  valorDevolucao: number | null;
  possuiExcedente: boolean | null;
  valorExcedente: number | null | string;
  possuiCargaEspecial: boolean | null;
  cargaEspecial: EnumCargaEspecial | null;
};

export type ConfiguracoesColaboradorNegociador = {
  idColaborador?: number;
  horarioInicio: string;
  horarioFim: string;
  diaSemanaTrabalhado: string;
  idConfiguracaoColaborador?: number;
};

export type ConfigColaboradorNegociador = {
  configColaboradorNegociador: ConfiguracoesColaboradorNegociador[];
};

export type listaColaboradorNegociador = ConfiguracoesColaboradorNegociador & {
  colaborador: {
    idColaborador: number;
    matricula: number;
    nome: string;
    ativo: boolean;
    roles: RoleType[];
    percentualMaximoAprovacao: number;
    negociacoesNegociador: null;
    negociacoesSupervisor: null;
    colaboradorPaginas: null;
    configuracaoColaborador: null;
  };
  configuracaoId: number;
};

export enum EnumCargaEspecial {
  Expressa = 'expressa',
  Martcon = 'martcon',
}

export enum EnumComponente {
  Entrega = 'entrega',
  Saida = 'saida',
  Km = 'km',
  EntregaKm = 'entregaKm',
}

export type MacroFilialRequest = {
  macroId?: number;
  descricao: string;
  tempoParaExpirarNegociacoesEmMinutos?: number;
  tempoParaExpirarLeilao?: number;
  filialId?: number;
};

export type BuscarFiliaisResponse = {
  id: number;
  descricaoSlim: string;
  isGreguimAtivo: boolean;
};

export type BuscarCidadesResponse = {
  cidades: BuscarCidades[];
  paginaAtual: number;
  totalDePaginas: number;
};

export type BuscarCidades = {
  id?: number;
  idCidade?: number;
  nomeCidade: string;
  isParticipanteLeilao?: boolean;
  codigoIbge: string;
  codigo: number;
  estado: string;
  estadoId?: number;
  macroId?: number;
  descricaoMacro?: string;
};

export type AtualizarCidadesRequest = {
  idCidade?: number;
  codigo?: number;
  nome?: string;
  idEstado?: number;
  codigoIbge?: string;
  idEstruturaLogisticaRegiao?: number;
  macroFilialIdMacroFilial?: number | null;
  isParticipanteLeiloes?: boolean;
};

export type CadastrarCidadesRequest = {
  codigo: number;
  nome: string;
  idEstado?: number;
  codigoIbge: string;
  idEstruturaLogisticaRegiao?: number;
  macroFilialId?: number;
};

export type EstadosResponse = {
  idEstado: number;
  sigla: string;
  cidades: null;
};
