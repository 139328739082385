import { ConexaoAPI } from '../../../shared/classes/conexaoAPI';
import { typeEnviarNovaPropostaOperation } from '../types/PropostaAEnviar';

import {
  ObservacaoNegociacaoRequest,
  ValoresRequest,
  MotoristasResponse,
  PropostasResponse,
  AtualizarPropostaRequest,
  CriarPropostaRequest,
  AtualizarNegociacaoRequest,
  Negociacao,
} from './informacoesNegociacaoApiTypes';

const URL_BASE = process.env.REACT_APP_URL_API_BASE;

export const buscar = async (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
): Promise<Negociacao> => {
  const URL_BUSCAR_DADOS_NEGOCIACAO = `${URL_BASE}/negociacoes/${idNegociacao}${
    idUsuarioMaster ? `?idUsuarioMaster=${idUsuarioMaster}` : ''
  }`;
  const conexao = new ConexaoAPI(URL_BUSCAR_DADOS_NEGOCIACAO, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<Negociacao>();
  const resposta = await conectar();
  return resposta.data;
};

export const salvarObservacao = async (
  idNegociacao: number,
  body: ObservacaoNegociacaoRequest,
): Promise<void> => {
  const URL_SALVAR_OBSERVACAO = `${URL_BASE}/negociacoes/${idNegociacao}`;
  const conexao = new ConexaoAPI(URL_SALVAR_OBSERVACAO, 'patch');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const salvarValores = async (
  idNegociacao: number,
  body: ValoresRequest,
): Promise<void> => {
  const URL_SALVAR_VALORES = `${URL_BASE}/negociacoes/${idNegociacao}`;
  const conexao = new ConexaoAPI(URL_SALVAR_VALORES, 'patch');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const buscarMotoristas = async (
  idFilial?: number | null,
  freteTabelado?: boolean | null,
): Promise<MotoristasResponse> => {
  const URL_BUSCAR_MOTORISTAS = `${
    process.env.REACT_APP_URL_API_MICRO_MOTORISTAS_BASE
  }/motoristas${
    idFilial
      ? `?idFilial=${idFilial}&isUsuarioApp=true&isParticipanteFreteTabelado=${freteTabelado}`
      : `?isUsuarioApp=true`
  }`;
  const conexao = new ConexaoAPI(URL_BUSCAR_MOTORISTAS, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<MotoristasResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export const buscarPropostas = async (
  idNegociacao: number,
  idUsuarioMaster?: number | null,
): Promise<PropostasResponse> => {
  const URL_BUSCAR_PROPOSTA = `${URL_BASE}/propostas/${idNegociacao}${
    idUsuarioMaster ? `?usuarioMaster=${idUsuarioMaster}` : ''
  }`;
  const conexao = new ConexaoAPI(URL_BUSCAR_PROPOSTA, 'get');
  const conectar = conexao.gerarFuncaoConectarRefreshToken<PropostasResponse>();
  const resposta = await conectar();
  return resposta.data;
};

export const criarProposta = async (
  idNegociacao: number,
  body: CriarPropostaRequest,
): Promise<typeEnviarNovaPropostaOperation> => {
  const URL_CRIAR_PROPOSTA = `${URL_BASE}/negociacoes/${idNegociacao}/propostas`;
  const conexao = new ConexaoAPI(URL_CRIAR_PROPOSTA, 'post');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<typeEnviarNovaPropostaOperation>();
  const resposta = await conectar();
  return resposta.data;
};

export const atualizarProposta = async (
  idNegociacao: number,
  idProposta: number,
  body: AtualizarPropostaRequest,
): Promise<void> => {
  const URL_ATUALIZAR_PROPOSTA = `${URL_BASE}/negociacoes/${idNegociacao}/propostas/${idProposta}`;
  const conexao = new ConexaoAPI(URL_ATUALIZAR_PROPOSTA, 'patch');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.data;
};

export const atualizarNegociacao = async (
  body: AtualizarNegociacaoRequest,
): Promise<number> => {
  const URL_ATUALIZAR_NEGOCIACAO = `${URL_BASE}/negociacoes/atualizar`;
  const conexao = new ConexaoAPI(URL_ATUALIZAR_NEGOCIACAO, 'patch');
  conexao.adicionarBody(body);
  const conectar = conexao.gerarFuncaoConectarRefreshToken<void>();
  const resposta = await conectar();
  return resposta.status;
};
